<template>
    <section class="grid grid-cols-1 sm:grid-cols-2 gap-4 items-end bg-white text-primary rounded-3xl p-8 shadow-lg">
        <div class="title-3 text-center col-span-full">Discover Your Hidden Costs ... And How Augwa Can Eliminate Them</div>
        <div>
            <div class="subtitle-1 text-center">How many hours per week do you spend preparing schedules, sending reminders, creating invoices, and collecting payments?</div>
            <div class="title-4 text-center">{{ adminHours[0] }} hours/week</div>
            <Slider v-model="adminHours" :min="1" :max="40" />
        </div>
        <div>
            <div class="subtitle-1 text-center">What is your hourly rate (how much is your time worth per hour)?</div>
            <div class="title-4 text-center">${{ hourlyRate[0] }}/hour</div>
            <Slider v-model="hourlyRate" :min="10"  />
        </div>
        <div class="title-2 text-center col-span-full">
            Augwa could save you ${{ savings }} per month. What could you do with that extra time and money?
        </div>
        <div class="title-2 text-center col-span-full">
            <ULink :to="localePath({ name:'get-started' })" class="link">Start automating today!</ULink>
        </div>
    </section>
</template>

<script setup lang="ts">
const props = defineProps<{
    subscriptionPrice: number,
}>()

const localePath = useLocalePath()

const adminHours = ref<number[]>([5])
const hourlyRate = ref<number[]>([25])

const savings = computed(() => {
    return Math.round((((adminHours.value[0] ?? 0) * .75) * (hourlyRate.value[0] ?? 0) * 4.33) - props.subscriptionPrice)
})
</script>