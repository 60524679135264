<template>
    <div>
        <!-- Hero Section -->
        <section class="text-center py-12 text-white">
            <h1 class="text-5xl font-bold">Manage Your Cleaning Business Efficiently</h1>
            <p class="mt-4 text-lg"> Running your cleaning business is about to get easier and more affordable! Augwa simplifies everything from scheduling to payments, crew management, and customer reminders — so you can focus on what matters.</p>
            <UButton
                color="secondary"
                size="xl"
                class="mt-6"
                :to="localePath({ name: 'get-started' })">Get Started</UButton>
        </section>

        <!-- Features Section -->
        <section class="py-12">
            <div class="container mx-auto text-center text-primary">
                <h2 class="text-3xl font-semibold text-white">Features</h2>
                <div class="grid md:grid-cols-4 gap-8 mt-8">
                    <div class="p-6 border rounded-lg bg-white">
                        <h3 class="text-xl font-semibold">Scheduling</h3>
                        <p>Effortless appointment management</p>
                    </div>
                    <div class="p-6 border rounded-lg bg-white">
                        <h3 class="text-xl font-semibold">Reminders</h3>
                        <p>Make missed appointments a thing of the past</p>
                    </div>
                    <div class="p-6 border rounded-lg bg-white">
                        <h3 class="text-xl font-semibold">Billing</h3>
                        <p>Automate invoices and payments</p>
                    </div>
                    <div class="p-6 border rounded-lg bg-white">
                        <h3 class="text-xl font-semibold">Client Management</h3>
                        <p>Track customer details and history</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-12">
            <RoiCalculator :subscription-price="lowestSubscriptionPrice" />
        </section>

        <!-- Pricing Section -->
        <section class="py-12">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-4xl text-center">
                    <h2 class="text-base/7 font-semibold text-white">Pricing</h2>
                    <p class="mt-2 text-balance text-5xl font-semibold tracking-tight text-white sm:text-6xl">Pricing that grows with you</p>
                </div>
                <p class="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-white sm:text-xl/8">Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer loyalty, and driving sales.</p>
                <div class="mt-16 flex justify-center">
                    <fieldset aria-label="Payment frequency">
                        <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs/5 font-semibold ring-1 ring-inset ring-gray-200">
                            <RadioGroupOption
                                v-for="option in frequencies"
                                :key="option.value"
                                v-slot="{ checked }"
                                as="template"
                                :value="option">
                                <div :class="[checked ? 'bg-secondary text-white' : 'text-gray-500', 'cursor-pointer rounded-full px-2.5 py-1']">{{ option.label }}</div>
                            </RadioGroupOption>
                        </RadioGroup>
                    </fieldset>
                </div>
                <USelectMenu
                    v-model="country"
                    label="Country"
                    :options="countryOptions.filter(x => x.value !== 'XX')"
                    class="max-w-72 mx-auto mt-6">
                    <template #label>
                        <template v-if="!country">
                            <span class="text-gray-400">Select a country</span>
                        </template>
                        <template v-else>
                            <UIcon v-if="country.icon" :name="country.icon" />
                            <span class="truncate">{{ country?.label }}</span>
                        </template>
                    </template>
                </USelectMenu>
                <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <div v-for="tier in tiers" :key="tier.id" :class="[tier.featured ? 'bg-secondary-700 ring-primary-900' : 'bg-white ring-gray-200', 'rounded-3xl p-8 ring-1 xl:p-10']">
                        <h3 :id="tier.id" :class="[tier.featured ? 'text-white' : 'text-gray-900', 'text-lg/8 font-semibold']">{{ tier.name }}</h3>
                        <p :class="[tier.featured ? 'text-gray-300' : 'text-gray-700', 'mt-4 text-sm/6']">{{ tier.description }}</p>
                        <p class="mt-6 flex items-baseline gap-x-1">
                            <span :class="[tier.featured ? 'text-white' : 'text-gray-900', 'text-4xl font-semibold tracking-tight']">{{ typeof tier.price === 'string' ? tier.price : tier.price[country.value][frequency.value] }}</span>
                            <span v-if="(typeof tier.price !== 'string')" :class="[tier.featured ? 'text-gray-300' : 'text-gray-700', 'text-sm/6 font-semibold']">{{ frequency.priceSuffix }}</span>
                        </p>
                        <a
                            :href="tier.href"
                            :target="tier.target"
                            :aria-describedby="tier.id"
                            :class="[tier.featured ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white' : 'bg-primary text-white shadow-sm hover:bg-secondary focus-visible:outline-secondary-600', 'mt-6 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">{{ tier.cta }}</a>
                        <ul role="list" :class="[tier.featured ? 'text-gray-300' : 'text-gray-700', 'mt-8 space-y-3 text-sm/6 xl:mt-10']">
                            <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                                <CheckIcon :class="[tier.featured ? 'text-white' : 'text-secondary', 'h-6 w-5 flex-none']" aria-hidden="true" />
                                {{ feature }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-4xl font-semibold tracking-tight text-white sm:text-5xl">Frequently asked questions</h2>
                    <p class="mt-6 text-base/7 text-white">Have a different question and can’t find the answer you’re looking for? Reach out to our support team by opening a chat.</p>
                </div>
                <div class="mt-20">
                    <dl class="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                        <div v-for="faq in faqs" :key="faq.id">
                            <dt class="text-base/7 font-semibold text-white">{{ faq.question }}</dt>
                            <dd class="mt-2 text-base/7 text-gray-300">{{ faq.answer }}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts" setup>
import countryOptions from '~/lib/supported-countries'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'

definePageMeta({
    layout: 'default',
})

const geolocation = useGeolocation()
const localePath = useLocalePath()

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/month' },
]

const frequency = ref(frequencies.at(-1))
const country = ref(countryOptions.find(c => c.value === geolocation.country) ?? countryOptions.find(c => c.value === 'US'))
const lowestSubscriptionPrice = computed(() => country.value?.value === 'CA' ? 39 : 29)

const tiers = computed(() => [
    {
        name: 'Starter',
        id: 'tier-starter',
        href: localePath({ name: 'get-started' }),
        price: { CA: { monthly: '$49', annually: '$39' }, US: { monthly: '$39', annually: '$29' } },
        description: 'Helping your small team stay organized and grow.',
        features: [
            'Automated scheduling',
            'Quotes & Invoices',
            'Automated reminders',
            'Automated billing',
            'Online payments',
            'Client management',
            '3 staff included',
            '24-hour support response time',
        ],
        featured: false,
        cta: 'Start 30-day free trial',
    },
    {
        name: 'Professional',
        id: 'tier-professional',
        href: localePath({ name: 'get-started' }),
        price: { CA: { monthly: '$119', annually: '$99' }, US: { monthly: '$99', annually: '$79' } },
        description: 'A plan that scales with your rapidly growing business.',
        features: [
            'Automated scheduling',
            'Quotes & Invoices',
            'Automated reminders',
            'Automated billing',
            'Online payments',
            'Client management',
            `10 staff included`,
            `Additional staff $${country.value?.value === 'CA' ? 12 : 10}/month`,
            '24-hour support response time',
        ],
        featured: false,
        cta: 'Start 30-day free trial',
    },
    {
        name: 'Enterprise',
        id: 'tier-enterprise',
        href: 'mailto:hello@augwa.com?subject=Enterprise%20Plan',
        target: '_blank',
        price: 'Custom',
        description: 'Dedicated support and infrastructure for your company.',
        features: [
            'Advanced analytics',
            'Custom reports',
            'Custom domains',
            'Priority support',
            'Custom integrations',
        ],
        featured: true,
        cta: 'Contact sales',
    },
])

const faqs = computed(() => [
    {
        "id": 1,
        "question": "What is Augwa?",
        "answer": "Augwa is an all-in-one business management platform designed specifically for residential cleaning businesses. It helps automate scheduling, payments, client management, invoicing, and staff coordination.",
    },
    {
        "id": 2,
        "question": "Who is Augwa for?",
        "answer": "Augwa is built for residential cleaning businesses looking to streamline operations, reduce administrative workload, and improve efficiency through automation.",
    },
    {
        "id": 3,
        "question": "How does Augwa help my business?",
        "answer": "Augwa automates key tasks such as scheduling, booking notifications, invoicing, and payments, reducing manual work and improving cash flow.",
    },
    {
        "id": 4,
        "question": "How does scheduling work in Augwa?",
        "answer": "Augwa offers automated scheduling, allowing business owners to efficiently manage their appointments and avoid conflicts.",
    },
    {
        "id": 5,
        "question": "Can I accept payments through Augwa?",
        "answer": "Yes, Augwa supports both online and offline payments, ensuring flexible and fast transaction processing.",
    },
    {
        "id": 6,
        "question": "Does Augwa support client management?",
        "answer": "Yes, Augwa includes a basic CRM to help you track client information, history, and communication for better customer relationships.",
    },
    {
        "id": 7,
        "question": "How does Augwa handle invoicing?",
        "answer": "Augwa automates invoice generation, making it easy to send invoices and track payments with minimal effort.",
    },
    {
        "id": 8,
        "question": "Can Augwa help reduce no-shows?",
        "answer": "Yes, Augwa sends automated booking reminders and status updates to reduce cancellations and missed appointments.",
    },
    {
        "id": 9,
        "question": "Is Augwa easy to use?",
        "answer": "Yes, Augwa is designed to be intuitive and user-friendly, requiring minimal training to get started.",
    },
    {
        "id": 10,
        "question": "How long does it take to set up Augwa?",
        "answer": "You can get started with Augwa in minutes, with an easy onboarding process and step-by-step setup guidance.",
    },
    {
        "id": 11,
        "question": "Can Augwa support my growing business?",
        "answer": "Yes, Augwa is designed to scale with your business, offering automation and organization tools that grow as you do.",
    },
    {
        "id": 12,
        "question": "Is my data secure with Augwa?",
        "answer": "Yes, Augwa prioritizes security and reliability, ensuring your business and client data remain safe.",
    },
    {
        "id": 13,
        "question": "What support options are available?",
        "answer": "Augwa provides customer support to help with any issues, ensuring a smooth experience for your business.",
    },
    {
        "id": 14,
        "question": "How much does Augwa cost?",
        "answer": `Pricing starts at $${country.value?.value === 'CA' ? 39 : 29} per month, providing essential tools for scheduling, payments, invoicing, and client management.`,
    },
    {
        "id": 15,
        "question": "Do I have to sign a long-term contract?",
        "answer": "No, Augwa offers flexible plans without long-term commitments, allowing you to choose what works best for your business.",
    },
    {
        "id": 16,
        "question": "Can I try Augwa before committing?",
        "answer": "Yes, Augwa offers a 30-day free trial so you can explore its features before making a decision, no credit card required!",
    },
])
</script>
