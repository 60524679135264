<template>
    <SliderRoot
        v-model="sliderValue"
        class="relative flex items-center select-none touch-none h-5 my-4"
        :min
        :max
        :step>
        <SliderTrack class="bg-gray-500/30 relative grow rounded-full h-2">
            <SliderRange class="absolute bg-gray-500 rounded-full h-full" />
        </SliderTrack>
        <SliderThumb
            class="block w-6 h-6 bg-secondary rounded-full hover:bg-primary shadow-sm focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
            aria-label="Volume"
        />
    </SliderRoot>
</template>

<script setup lang="ts">
import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'reka-ui'

withDefaults(
    defineProps<{
    min?: number
    max?: number
    step?: number
}>(),
    {
        min: 0,
        max: 100,
        step: 1,
    })

const sliderValue = defineModel<number[]>({ default: [50] })
</script>